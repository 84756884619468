import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Register } from "./pages/register";
import { VerifyPhone } from "./pages/verify-auth";
import { SuccessPage } from "./pages/success";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Register />} />
        <Route path='/verify' element={<VerifyPhone />} />
        <Route path='/success' element={<SuccessPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
