import { useState } from "react";
import HellEnergyLogo from "../assets/logo.svg";
import { toast } from "react-toastify";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../loader";

export function VerifyPhone() {
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  console.log({ state });

  const handleValidateToken = async () => {
    try {
      setLoading(true);
      const {
        data: { res },
      } = await axios.post(
        `${process.env.REACT_APP_FIREBASE_URL}/phoneauth/verify-totp`,
        {
          phoneNumber: state?.formValues?.phoneNumber,
          token,
        }
      );
      window.analytics.track("Phone Verified", {
        phone: state?.formValues?.phoneNumber,
      });
      await handleRegister(res);
    } catch (error) {
      setLoading(false);
      console.log({ error });
      toast.error("Failed to validate token. Please try again later.");
    }
  };

  const handleRegister = async (values) => {
    try {
      await axios.post(`${process.env.REACT_APP_AWS_API}/cunsumers/create`, {
        ...state.formValues,
        event: { eventType: "party", source: "app", ...state.event },
        token: values.accessToken,
      });
      navigate("/success");
    } catch (error) {
      setLoading(false);
      console.log("error registering user", error);
      toast.error(
        "Oops! There must be a problem somewhere. Please try again later."
      );
    }
  };

  const handleResendToken = async () => {
    try {
      toast.info("Sending Token, please wait...");
      await axios.post(
        `${process.env.REACT_APP_FIREBASE_URL}/phoneauth/send-totp`,
        {
          mode: "whatsapp",
          phoneNumber: state.formValues.phoneNumber,
          domain: "tradedepot",
        }
      );
      toast.success("Token sent to your whatsapp number");
    } catch (error) {
      toast.error("Oops! Something went wrong. Please try again later.");
      console.log({ error });
      setLoading(false);
    }
  };

  return (
    <div className='h-energy-page'>
      <div className='h-energy-container h-energy-verify-container'>
        <img
          src={HellEnergyLogo}
          alt='Hell Energy logo'
          className='energy-logo'
        />
        <div className='form'>
          <h3>Verify your phone number</h3>
          <h6>
            We've sent a verification code to the phone number you provided.
            Please enter the code below.
          </h6>

          <div className='custom-input-group'>
            <label>Enter Code</label>
            <input
              className='form-control'
              value={token}
              id='token'
              //   type='number'
              onChange={({ target: { value } }) => {
                if (value.length < 7) setToken(value);
              }}
              placeholder='000000'
            />
          </div>
          <h5 style={{ fontWeight: 400 }}>
            Not getting a code? <span onClick={handleResendToken}>Resend</span>
          </h5>
          <button
            className='submit-btn'
            disabled={token.length !== 6 || loading}
            onClick={handleValidateToken}
          >
            {loading ? <Loader /> : "Verify"}
          </button>
        </div>
      </div>
    </div>
  );
}
