import * as Yup from "yup";

export const RegisterSchemaPageOne = Yup.object().shape({
  name: Yup.string().required("Please provide your full name"),
  gender: Yup.string().required("Please provide your gender"),
  email: Yup.string().email().required("Please provide a valid email"),
  dob: Yup.string().required("Please provide your date of birth"),
  phoneNumber: Yup.string().required("Please provide your phone number"),
  city: Yup.string(),
  state: Yup.string(),
  zip: Yup.string(),
  country: Yup.string(),
});

export const RegisterSchemaPageTwo = Yup.object().shape({
  // facebook: Yup.string().required("Provide your facebook username"),
  // instagram: Yup.string().required("Provide your instagram username"),
  // twitter: Yup.string().required("Provide your twitter username"),
  // tiktok: Yup.string().required("Provide your tiktok username"),
});
