import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { RegisterSchemaPageOne } from "../schemas/register.schema";
import HellEnergyBanner from "../assets/banner.jpg";
import HellEnergyLogo from "../assets/logo.svg";
import axios from "axios";
import { useState } from "react";
import { validateEmail } from "../utils";
import { toast } from "react-toastify";
import { Loader } from "../loader";

export function Register() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(location.search);
  const searchParams = Object.fromEntries(urlParams.entries());
  const formik = useFormik({
    initialValues: {
      name: "",
      gender: "",
      email: "",
      dob: "",
      phoneNumber: "",
      country: "",
      socialHandles: {
        instagram: "",
        tiktok: "",
      },
    },
    onSubmit: (values) => handleSubmit(values),
    validationSchema: RegisterSchemaPageOne,
  });

  const handleSubmit = async (values) => {
    // validate email
    if (!validateEmail(values.email))
      return formik.setFieldError("email", "Invalid email format");

    // validate phone number
    const numberIsValid = isValidPhoneNumber(values.phoneNumber);
    if (!numberIsValid)
      return formik.setFieldError("phoneNumber", "Invalid phone number format");

    // send phone number OTP
    handleVerifyPhone();

    // track sign-up
    trackSignupEvent();
  };

  const handleVerifyPhone = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_FIREBASE_URL}/phoneauth/send-totp`,
        {
          mode: "whatsapp",
          phoneNumber: formik.values.phoneNumber,
          domain: "tradedepot",
        }
      );
      navigate("/verify", {
        state: {
          formValues: {
            ...formik.values,
          },
          event: searchParams,
        },
      });
    } catch (error) {
      setLoading(false);
      toast.error("Oops! Something went wrong. Please, try again later.");
      console.log("sss", error);
    }
  };

  const trackSignupEvent = () => {
    try {
      const { values } = formik;

      const formData = {
        name: values?.name,
        email: values?.email,
        phone: values?.phoneNumber,
        gender: values?.gender,
        birthday: values?.dob,
        instagram_handle: values?.socialHandles?.instagram,
        tiktok_handle: values?.socialHandles?.tiktok,
        source: "Hell Africa Landing Page",
        url_params: { ...searchParams },
      };

      window.analytics.identify(values?.phoneNumber, formData);
      window.analytics.track("Signup Completed", formData);
    } catch (error) {
      console.error("Error tracking signup event:", error);
    }
  };

  return (
    <div className='h-energy-page'>
      <img
        src={HellEnergyBanner}
        alt='hell-energy-banner'
        className='img-fluid'
        style={{ width: "100vw" }}
      />
      <div className='container h-energy-container'>
        <img
          src={HellEnergyLogo}
          alt='Hell Energy logo'
          className='energy-logo'
        />
        <div className='form'>
          <>
            <h3>Join the VIP Club – Raise Hell with Exclusive Perks!</h3>
            <h6>Let’s get you in!</h6>
            <div className='custom-input-group'>
              <label>Full Name</label>
              <input
                className='form-control'
                value={formik.values.name}
                id='name'
                onChange={formik.handleChange}
                placeholder='Enter your full name'
              />
              {formik.errors?.name && formik.touched?.name && (
                <p className='error-text'>{formik.errors.name}</p>
              )}
            </div>
            <div className='custom-input-group'>
              <label>WhatsApp Number</label>
              {/* <input
                className='form-control'
                value={formik.values.phoneNumber}
                // type='number'
                placeholder='+234'
                id='phoneNumber'
                onChange={({ target: { value } }) => {
                  if (value.length < 15)
                    formik.setFieldValue("phoneNumber", value);
                }}
              /> */}
              <PhoneInput
                placeholder='Enter your whatsapp number'
                international
                countryCallingCodeEditable={false}
                defaultCountry='NG'
                value={formik.values.phoneNumber}
                onChange={(number) => {
                  formik.setFieldValue("phoneNumber", number);
                }}
              />
              {formik.errors?.phoneNumber && formik.touched?.phoneNumber && (
                <p className='error-text'>{formik.errors.phoneNumber}</p>
              )}
            </div>
            <div className='custom-input-group'>
              <label>Gender</label>
              <select
                className='form-select custom-select'
                value={formik.values.gender || null}
                id='gender'
                onChange={formik.handleChange}
              >
                <option selected disabled>
                  Select Gender
                </option>
                <option value={"Male"}>Male</option>
                <option value={"Female"}>Female</option>
                <option value={"Other"}>Other</option>
              </select>
              {formik.errors?.gender && formik.touched?.gender && (
                <p className='error-text'>{formik.errors.gender}</p>
              )}
            </div>
            <div className='custom-input-group'>
              <label>Your Birthday</label>
              <DatePicker
                selected={formik.values.dob}
                onChange={(date) => formik.setFieldValue("dob", date)}
                peekNextMonth
                showMonthDropdown
                placeholderText='MM/DD/YYYY'
                showYearDropdown
                dropdownMode='select'
              />
              {formik.errors?.dob && formik.touched?.dob && (
                <p className='error-text'>{formik.errors.dob}</p>
              )}
            </div>
            <div className='custom-input-group'>
              <label>Email</label>
              <input
                className='form-control'
                value={formik.values.email}
                id='email'
                placeholder='Enter your email'
                onChange={formik.handleChange}
              />
              {formik.errors?.email && formik.touched?.email && (
                <p className='error-text'>{formik.errors.email}</p>
              )}
            </div>
            <div className='custom-input-group'>
              <label>
                Instagram <i>(Optional)</i>
              </label>
              <div className='input-flex'>
                <i className='bi bi-instagram'></i>
                <input
                  className='form-control'
                  placeholder='Enter your instagram handle'
                  value={formik.values.socialHandles.instagram}
                  id='socialHandles.instagram'
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className='custom-input-group'>
              <label>
                Tiktok <i>(Optional)</i>
              </label>
              <div className='input-flex'>
                <i className='bi bi-tiktok'></i>
                <input
                  className='form-control'
                  placeholder='Enter your tiktok handle'
                  value={formik.values.socialHandles.tiktok}
                  id='socialHandles.tiktok'
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <button
              disabled={loading}
              className='submit-btn'
              onClick={formik.handleSubmit}
            >
              {loading ? <Loader /> : "Submit"}
            </button>
          </>
          {/* {screenPage === 1 ? null : (
            <>
              <h3>Social Media handles</h3>
              <h6>Please fill in your social media handles</h6>
              <div>
                <label>
                  Facebook <i>(Optional)</i>
                </label>
                <div className='input-flex'>
                  <i className='bi bi-facebook'></i>
                  <input
                    className='form-control'
                    placeholder='Enter your facebook handle'
                    value={formik.values.facebook}
                    id='facebook'
                    onChange={formik.handleChange}
                  />
                </div>
                {formik.errors?.facebook && formik.touched?.facebook && (
                  <p className='error-text'>{formik.errors.facebook}</p>
                )}
              </div>
              <div>
                <label>
                  Twitter <i>(Optional)</i>
                </label>
                <div className='input-flex'>
                  <i className='bi bi-twitter'></i>
                  <input
                    className='form-control'
                    placeholder='Enter your twitter handle'
                    value={formik.values.twitter}
                    id='twitter'
                    onChange={formik.handleChange}
                  />
                </div>
                {formik.errors?.twitter && formik.touched?.twitter && (
                  <p className='error-text'>{formik.errors.twitter}</p>
                )}
              </div>
              <div>
                <label>
                  Instagram <i>(Optional)</i>
                </label>
                <div className='input-flex'>
                  <i className='bi bi-instagram'></i>
                  <input
                    className='form-control'
                    placeholder='Enter your instagram handle'
                    value={formik.values.instagram}
                    id='instagram'
                    onChange={formik.handleChange}
                  />
                </div>
                {formik.errors?.instagram && formik.touched?.instagram && (
                  <p className='error-text'>{formik.errors.instagram}</p>
                )}
              </div>
              <div>
                <label>
                  Tiktok <i>(Optional)</i>
                </label>
                <div className='input-flex'>
                  <i className='bi bi-tiktok'></i>
                  <input
                    className='form-control'
                    placeholder='Enter your tiktok handle'
                    value={formik.values.tiktok}
                    id='tiktok'
                    onChange={formik.handleChange}
                  />
                </div>
                {formik.errors?.tiktok && formik.touched?.tiktok && (
                  <p className='error-text'>{formik.errors.tiktok}</p>
                )}
              </div>
              <button disabled={loading} onClick={formik.handleSubmit}>
                {loading ? <Loader /> : "Submit"}
              </button>
            </>
          )} */}
        </div>
      </div>
    </div>
  );
}
