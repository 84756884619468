import HellEnergyBanner from "../assets/banner.jpg";

export function SuccessPage() {
  return (
    <>
      <img
        src={HellEnergyBanner}
        alt='hell-energy-banner'
        className='img-fluid'
        style={{ width: "100vw" }}
      />
      <div className='h-energy-page h-success'>
        <span style={{ fontSize: "82px" }}>🎉</span>
        {/* <img src={SuccessSvg} alt='hell-energy-banner' className='img-fluid' /> */}
        <div
          className='container h-energy-container'
          style={{ paddingTop: "20px" }}
        >
          <h2>You’re In! Get Ready for Something Epic.</h2>
          <h4 style={{ marginTop: "60px" }}>&copy; HELL ENERGY AFRICA</h4>
        </div>
      </div>
    </>
  );
}
